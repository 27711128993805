import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  /**
   * Opens a confirmation modal with the provided data and returns a promise that resolves to a boolean value.
   *
   * @param {any} data - The data used to configure the confirmation modal. It can contain the following properties:
   *                    - dialogSize: The size of the modal.
   *                    - title: The title of the modal.
   *                    - message: The message to be displayed in the modal.
   *                    - btnOkText: The text for the OK button.
   *                    - btnCancelText: The text for the Cancel button.
   *                    - extraIcon: The extra icon to be displayed in the modal.
   *                    - iconClass: The class of the icon to be displayed in the modal.
   * @return {Promise<boolean>} A promise that resolves to a boolean value indicating the user's choice.
   */
  public confirm(data: any): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: data?.dialogSize,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = data?.title;
    modalRef.componentInstance.message = data?.message;
    modalRef.componentInstance.btnOkText = data?.btnOkText;
    modalRef.componentInstance.btnCancelText = data?.btnCancelText;
    modalRef.componentInstance.extraIcon = data?.extraIcon;
    modalRef.componentInstance.iconClass = data?.iconClass;
    return modalRef.result;
  }
}
