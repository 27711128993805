import { Component, DestroyRef, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

/**
 * Creates a function that returns an Observable that completes when the component is destroyed.
 *
 * @return {() => Observable<boolean>} A function that returns an Observable that emits true when the component is destroyed.
 */
export const destroy = () => {
  const subject = new Subject();
  inject(DestroyRef).onDestroy(() => {
    subject.next(true);
    subject.complete();
  });

  return () => takeUntil(subject.asObservable());
};
