<div class="confirm-dialog">
  <div class="modal-header" *ngIf="title">
    <h3 class="modal-title">{{ title }}</h3>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="extraIcon">
      <ng-container
        *ngIf="extraIcon.includes('.png') || extraIcon.includes('.svg'); else defaultIconRef">
        <img class="img-custom-icon" alt="" [lazyLoad]="extraIcon" [ngClass]="iconClass" />
      </ng-container>
      <ng-template #defaultIconRef>
        <i class="{{ extraIcon }} middle" [ngClass]="iconClass || 'text-primary'"></i>
      </ng-template>
    </ng-container>
    <p class="message" [class.mt-2]="extraIcon">{{ message }}</p>
  </div>
  <div class="modal-footer d-flex justify-content-center align-items-center gap-3">
    <button type="button" class="btn btn-outline close" (click)="decline()">
      {{ btnCancelText ? btnCancelText : 'Cancel' }}
    </button>
    <button type="button" class="btn btn-primary" (click)="accept()">
      {{ !btnOkText ? 'OK' : btnOkText }}
    </button>
  </div>
</div>
