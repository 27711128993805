import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedDataService } from '@app/core/services';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ConfirmationDialogService } from '@app/core/services/confirmation-modal.service';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  imports: [CommonModule, LazyLoadImageModule],
  // providers: [ConfirmationDialogService],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() btnOkText!: string;
  @Input() btnCancelText!: string;
  @Input() extraIcon!: string;
  @Input() iconClass!: any;

  /**
   * Constructs a new instance of the class.
   *
   * @param {NgbActiveModal} activeModal - The active modal service.
   * @param {SharedDataService} sharedDataService - The shared data service.
   */
  constructor(
    private activeModal: NgbActiveModal,
    public sharedDataService: SharedDataService,
  ) {}

  ngOnInit() {}

  /**
   * Closes the modal with a value of `false` to indicate declining.
   *
   * @return {void} This function does not return anything.
   */
  public decline() {
    this.closeModal(false);
  }

  /**
   * Closes the modal with a value of true.
   *
   * @return {void}
   */
  public accept() {
    this.closeModal(true);
  }

  /**
   * Dismisses the active modal.
   *
   * @return {void} This function does not return anything.
   */
  public dismiss() {
    this.activeModal.dismiss();
  }

  /**
   * Closes the modal with the specified value.
   *
   * @param {boolean} value - The value to close the modal with.
   * @return {void} This function does not return anything.
   */
  private closeModal(value: boolean): void {
    this.activeModal.close(value);
  }
}
